/**
 * scrollTo w/ callback when scroll finishes
 * @param {HTMLElement} el
 * @param {Number} offset
 * @param {Function} callback
 */
export function scrollTo(el, offset, callback, behavior = 'smooth') {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (el.scrollTop.toFixed() === fixedOffset) {
      el.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  el.addEventListener('scroll', onScroll);
  onScroll();
  el.scrollTo({
    top: offset,
    behavior,
  });
}
