<script setup>
// how many links should be rendered at
// dates slider at the top
const DATE_PAGESIZE = 5;
// how many programs should be shown
// from top before destination date on
const SCROLL_ROWS_FROMTOP = 4;

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Mousewheel } from 'swiper';
import { epgBreakPoints } from '@/components/ui/breakPoints';
import {
  startOfDay,
  format,
  isToday,
  isYesterday,
  isFuture,
  isPast,
  isEqual,
  isAfter,
  isBefore,
  isSameDay,
  isTomorrow,
  getUnixTime,
} from 'date-fns';
import ru from 'date-fns/locale/ru/index.js';
import { noop } from 'lodash-es';
import { scrollTo } from '@/utils/ui/scroll';
import Icon from '@/components/common/icons/icon.vue';
import { getDefaultImage } from '@/utils/ui/getDefaultImage';

const emit = defineEmits(['program']);

const props = defineProps({
  // format: as in smartapi answer
  epg: {
    type: Array,
    default: () => [],
  },
  // js timestamp (milliseconds)
  currentDate: {
    type: Number,
    default: () => Date.now(),
  },
  channel_id: {
    type: Number,
    default: 0
  },
});

// const channel = inject('channel');

let programRefs = {};
let skipScrollObserve = true;

const epgList = shallowRef([]);
const epgDates = shallowRef([]);
const currDate = shallowRef(null);
const observer = shallowRef(null);
const scrollRef = shallowRef(null);
const refsFilled = shallowRef(false);
const initialSlide = shallowRef(0);

const visibleDates = computed(() => {
  const days = [...epgDates.value];
  const curday = currDate.value;
  const curidx = days.findIndex(d => isSameDay(curday, d));
  let istart = curidx - 2;
  let iend = istart + DATE_PAGESIZE;

  if (istart < 0) {
    istart = 0;
  }

  if (istart > days.length - DATE_PAGESIZE) {
    istart = days.length - DATE_PAGESIZE;
  }

  if (iend - istart < DATE_PAGESIZE) {
    iend = DATE_PAGESIZE - istart;
  }

  if (iend > days.length - 1) {
    iend = days.length;
  }

  initialSlide.value = curidx - 1;
  return days;
  // const visible = days.slice(istart, iend);
  // return visible;
});
const isDateScrollableNext = computed(() => {
  const days = [...epgDates.value];
  const curday = currDate.value;
  const curidx = days.findIndex(d => isSameDay(curday, d));
  return curidx + 1 < days.length;
});
const isDateScrollablePrev = computed(() => {
  const days = [...epgDates.value];
  const curday = currDate.value;
  const curidx = days.findIndex(d => isSameDay(curday, d));
  return curidx > 0;
});

watchEffect(() => {
  const _epgDates = new Set();
  const _epgList = [];

  let cdate = new Date();
  let ncdate = Number(props.currentDate);
  if (!isNaN(ncdate)) {
    cdate = new Date(ncdate);
  }

  // unobserve epg list
  Object.values(programRefs).forEach(el => {
    if (observer.value === null) {
      return;
    }
    observer.value.unobserve(el);
  });
  programRefs = {};

  // collect dates & programs
  props.epg.forEach(([id, title, begin, end, ageRating, categories]) => {
    const start = new Date(begin * 1000);
    const finish = new Date(end * 1000);

    if (isCurrentProgram(start, finish, cdate)) {
      emit('program', { id, title, begin: start, end: finish });
      currDate.value = start;
    }

    _epgList.push({
      id,
      title,
      begin: start,
      end: finish,
      ageRating: ageRating,
      categories: categories.join(', ').trim(),
    });
    _epgDates.add(startOfDay(start).valueOf());
  });

  // fill dates & programs
  epgDates.value = Array.from(_epgDates)
    .sort()
    .map(ts => new Date(ts));
  epgList.value = _epgList.sort((a, b) => (a.begin > b.begin ? 1 : -1));
});

const heightEpgChild = ref(null);
let resizeTimer;
onMounted(() => {
  observer.value = new IntersectionObserver(scrollObserverCallback, {
    root: scrollRef.value,
    trackVisibility: true,
    delay: 500,
  });

  const firstElement = document.querySelector('.epg_dialog__epg_list_container').firstElementChild;
  function calculateTotalHeight() {
    const totalHeight = firstElement.offsetHeight +
        parseInt(window.getComputedStyle(firstElement).marginTop) +
        parseInt(window.getComputedStyle(firstElement).marginBottom);
    heightEpgChild.value = totalHeight;
  }
  calculateTotalHeight();
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      scrollToDate(
          currDate.value,
          () => {
            skipScrollObserve = false;
          },
          'auto',
      );
      calculateTotalHeight()
    }, 300);
  });
});
onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
  programRefs = {};

  if(process.server) return null;
  if (resizeTimer) {
    clearTimeout(resizeTimer);
    resizeTimer = null;
  }
});
// first render: scroll to prop date
watch(refsFilled, () => {
  scrollToDate(
    currDate.value,
    () => {
      skipScrollObserve = false;
    },
    'auto',
  );
});
function onClickProgram(program) {
  // navigateTo({ name: 'channel-id', params: { id: props.channel.id }, query: { time: getUnixTime(program.begin.valueOf()) } });
  navigateTo({
    name: 'channel-arg',
    params: { arg: [props.channel_id, getUnixTime(program.begin.valueOf())] },
  });
}

const generateUrl = (program) => `/channel/${props.channel_id}/${getUnixTime(program.begin.valueOf())}`;

function onClickSlider(dir) {
  const days = [...epgDates.value];
  const curday = currDate.value;
  const curidx = days.findIndex(d => isSameDay(curday, d)); // start of day
  const tgtDt = new Date(days[curidx + dir]);
  tgtDt.setHours(14);
  currDate.value = tgtDt;
  skipScrollObserve = true;
  scrollToDate(currDate.value, () => {
    skipScrollObserve = false;
  });
}
function onClickDate(sod) {
  // target date to scroll is +-14:00
  const _sod = new Date(sod);
  // _sod.setHours(14);
  _sod.setHours(11);
  currDate.value = _sod;
  skipScrollObserve = true;
  scrollToDate(currDate.value, () => {
    skipScrollObserve = false;
  });
}
function scrollToDate(dest, cb = noop, behavior = 'smooth') {
  let begin;
  // find first closest program
  for (let d of Object.keys(programRefs)) {
    const dt = new Date(d.valueOf());
    if (!(isEqual(dt, dest) || isAfter(dt, dest))) {
      continue;
    }
    begin = dt;
    break;
  }
  const el = programRefs[begin];
  if (!el) {
    return;
  }
  const { height } = el.getBoundingClientRect();
  const totalHeight = height +
      parseInt(window.getComputedStyle(el).marginTop) +
      parseInt(window.getComputedStyle(el).marginBottom);
  scrollTo(scrollRef.value, el.offsetTop - totalHeight * SCROLL_ROWS_FROMTOP, cb, behavior);
}
const fillProgramRefs = begintime => ref => {
  if (Object.keys(programRefs).length === epgList.value.length || ref === null || observer.value === null) {
    return;
  }  
  observer.value.observe(ref);
  programRefs[begintime] = ref;
  if (Object.keys(programRefs).length === epgList.value.length) {
    refsFilled.value = true;
  }
};
function scrollObserverCallback(entries) {
  if (skipScrollObserve) {
    return;
  }
  for (let entry of entries) {
    if (!(entry.isIntersecting && entry.isVisible)) {
      continue;
    }
    const idx = Object.values(programRefs).findIndex(e => e.isSameNode(entry.target));
    let dt = Object.keys(programRefs)[idx];
    dt = new Date(dt);
    if (isSameDay(currDate.value, dt)) {
      // first visible
      return;
    }

    currDate.value = startOfDay(dt);
    return;
  }
}
function dateFmt(dt) {
  if (isToday(dt)) {
    return 'Сегодня';
  }
  if (isYesterday(dt)) {
    return 'Вчера';
  }
  if (isTomorrow(dt)) {
    return 'Завтра';
  }
  return format(dt, 'EEEEEE, d.LL', { locale: ru });
}
function isCurrentProgram(start, end, base) {
  return (isEqual(base, start) || isAfter(base, start)) && isBefore(base, end);
}

function putErrorImage(event) {
  event.target.src = getDefaultImage('poster_80_80');
}

const touchpadScrollCoefficient = 0.2;
let lastDeltaY = 0;
let isSnapping = false;
let scrollTimeout;

function handleScroll(event) {
  event.preventDefault();

  if (isSnapping) {
    return;
  }

  let deltaY = event.deltaY;
  lastDeltaY = deltaY;

  if (event.deltaY > 50 || event.deltaY < -50) {
    if (event.deltaY > 50) {
      scrollRef.value.scrollTop += heightEpgChild.value;
    } else {
      scrollRef.value.scrollTop -= heightEpgChild.value;
    }
  } else {
    deltaY *= touchpadScrollCoefficient;
    scrollRef.value.scrollTop += deltaY;

    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      requestAnimationFrame(() => {
        snapToNearestElement(deltaY);
      });
    }, 100); // Увеличен тайм-аут для уменьшения частоты вызовов
  }
}

function snapToNearestElement(deltaY) {
  if (!scrollRef.value) {
    return;
  }

  const scrollTop = scrollRef.value.scrollTop;
  const children = scrollRef.value.children;
  let nearestElement = null;
  let minDistance = Infinity;

  for (let child of children) {
    const childTop = child.offsetTop;
    const distance = Math.abs(childTop - scrollTop);

    // Check direction and ensure we only consider elements in the scroll direction
    if ((deltaY > 0 && childTop >= scrollTop) || (deltaY < 0 && childTop <= scrollTop)) {
      if (distance < minDistance) {
        minDistance = distance;
        nearestElement = child;
      }
    }
  }

  if (nearestElement) {
    isSnapping = true;
    const scrollOptions = {
      top: nearestElement.offsetTop,
      behavior: 'smooth'
    };
    scrollRef.value.scrollTo(scrollOptions);

    // Set a timeout to reset the snapping flag after the smooth scroll finishes
    setTimeout(() => {
      isSnapping = false;
    }, 500); // The duration here should match the expected scroll animation duration
  }
}
</script>

<template>
<!--  <div class="channel_page__logo">-->
<!--    <img-->
<!--      :src="channel.icon"-->
<!--      :alt="channel.name"-->
<!--      @error="putErrorImage"-->
<!--    />-->
<!--  </div>-->
  <div class="epg_card__content">
    <Swiper
      v-if="visibleDates.length"
      :navigation="true"
      :modules="[Navigation, Pagination]"
      :breakpoints="epgBreakPoints"
      :initial-slide="initialSlide"
    >
      <SwiperSlide
        v-for="date in visibleDates"
        :key="date.valueOf()"
        class="epg_dialog__date_element"
        :class="{ today: isSameDay(date, currDate) }"
        @click="onClickDate(date)"
      >
        <div class="epg_dialog__date_element2">
          {{ dateFmt(date) }}
        </div>
        <div
          class="epg_dialog__date_line"
          :class="{ today: isSameDay(date, currDate) }"
        ></div>
      </SwiperSlide>
    </Swiper>

    <div class="shadow-epg">
      <div
        ref="scrollRef"
        class="epg_dialog__epg_list_container"
        @wheel.prevent="handleScroll"
      >
        <a
          v-for="program in epgList"
          :key="program.begin.valueOf()"
          :ref="fillProgramRefs(program.begin)"
          class="epg_dialog__epg_list"
          :class="{ gray_text: isFuture(program.begin), pointer: isPast(program.begin) }"
          :href="generateUrl(program)"
          @click.prevent="onClickProgram(program)"
        >
          <div class="epg_dialog__epg_icon">
            <template v-if="isPast(program.begin)">
              <svg
                v-if="isPast(program.end)"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.45591 7.82024C4.99585 8.33916 5.87127 8.33916 6.41121 7.82024L14.2475 0.288973C14.6484 -0.0963235 15.2984 -0.0963249 15.6993 0.288972C16.1002 0.674269 16.1002 1.29896 15.6993 1.68426L6.41121 10.6108C5.87127 11.1297 4.99585 11.1297 4.45591 10.6108L0.300677 6.61733C-0.100226 6.23203 -0.100225 5.60734 0.300678 5.22204C0.701581 4.83675 1.35157 4.83675 1.75248 5.22204L4.45591 7.82024Z"
                  :fill="isCurrentProgram(program.begin, program.end, currentDate) ? '#4AC574' : '#EEEEEE'"
                />
              </svg>
              <Icon
                v-else
                icon="epg/icon_live_epg"
              />
            </template>
          </div>
          <div class="epg_dialog__program_time">
            {{ format(program.begin, 'HH:mm') }}
          </div>
          <div class="epg_dialog__program_title">
            {{ program.title }}
            <div class="epg_dialog__description">
              {{ program.categories }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
